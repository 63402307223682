<template>
    <div>
        <button class="btn btn-default" @click="showFiles" v-show="!showThumbnails">({{ numPhotos }}) Photos</button>
        <div v-show="showThumbnails">
            <div v-for="image in images"  :key="image.cdi_file_id" class="assetImage">
                <ProtectedLink :type="'download'" :linkType="'image'" :imgSrc="`${imgServer}/api/download/file/${image.cdi_file_id}?${queryStringSrc}&thumb=1`" :id="image.cdi_file_id" :queryString="queryStringLink" />
             <img src="" style="display: none" />
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";

export default {
    name: "Images",
    data() {
        return {
            state: store.state,
            showThumbnails: false,
        }
    },
    props: ['images'],
    components: {ProtectedLink},
    computed: {
        numPhotos: function() {
            if (this.images) {
                return this.images.length;
            }else{
                return '';
            }
        },
        imgServer: function() {
            return process.env.VUE_APP_URL;
        },
        queryStringSrc: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}&token=${this.$appStore.getUserFileAccessToken()}`;
        },
        queryStringLink: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        },

    },
    methods: {
        showFiles: function() {
            this.showThumbnails = !this.showThumbnails;
        }
    }
}
</script>

<style scoped>
.assetImage{
    float: left;
    margin-right: 5px;
}
</style>