<template>
    <div class="spacing_top">
        <form @submit.prevent="saveClaim">
            <table class="table">
                <thead>
                <tr>
                    <th>Claim #</th>
                    <th>Date</th>
                    <th>Cost</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="claim in claims" v-bind:key="claim.asset_claim_id">
                    <td>{{claim.claim_number}}</td>
                    <td>{{claim.claim_date}}</td>
                    <td>{{claim.claim_cost}}</td>
                    <td><a class="cdi_edit" @click="deleteClaimConfirm(claim.asset_claim_id)"><span class='glyphicon glyphicon-trash'></span></a></td>
                </tr>
                <tr>
                    <td><input type="text" v-model="formFields.claimNumber.val" class="form-control"></td>
                    <td><DatePicker v-model="formFields.claimDate.val" :inputAttributes="{class: 'form-control'}" /></td>
                    <td><input type="number" v-model="formFields.claimCost.val" class="form-control"></td>
                    <td><input class="btn btn-default btn-block" type="submit" value="Save"></td>
                </tr>
                </tbody>
            </table>
        </form>
        <ConfirmBox
            v-if="confirmBox"
            :heading="'Delete claim?'"
            :body="'Are you sure you want to delete this claim?'"
            @close="deleteClaim"
        />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import ConfirmBox from '@/components/utils/ConfirmBox';
import DatePicker from '@/components/utils/DatePicker';
import helpers from "@/store/helpers";

export default {
    name: "Claim",
    components: {
        ConfirmBox,
        DatePicker,
    },
    props: ['claims'],
    data() {
        return {
            state: store.state,
            formFields: {
                claimNumber: { val: '', qsField: '[claim_number]' },
                claimDate: { val: '', qsField: '[claim_date]' },
                claimCost: { val: 0, qsField: '[claim_cost]' },
            },
            confirmBox: false,
            deleteID: 0,
        }
    },
    methods: {
        saveClaim: function() {
            if (!this.formFields.claimNumber.val) {
                this.$appStore.errorBox('Must provide a claim number');
            }else if (!this.formFields.claimDate.val) {
                this.$appStore.errorBox('Must provide the date of the claim');
            }else{
                store.apiWithObjectLoad('save_claim', helpers.getQSPairs(this.formFields));
                this.formFields.claimNumber.val = '';
                this.formFields.claimDate.val = '';
                this.formFields.claimCost.val = 0;
            }
        },
        deleteClaimConfirm: function(assetClaimID) {
            this.deleteID = assetClaimID;
            this.confirmBox = true;
        },
        deleteClaim: function(confirm) {
            if (confirm){
                store.apiWithObjectLoad('delete_claim', {'params[asset_claim_id]': this.deleteID});
            }
            this.confirmBox = false;
            this.deleteID = 0;
        },
    }
}
</script>

<style scoped>

</style>