<template>
    <div class="spacing_top">
        <form>
            <table class="table table-responsive">
                <thead>
                <tr>
                    <th>Maintenance</th>
                    <th>Frequency</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>File</th>
                    <th>Disable</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="maint in maintenance" v-bind:key="maint.asset_maintenance_schedule_id">
                    <td><input class="form-control" type="text" v-model="maint.name" @keyup.enter="updateMaint(maint)"></td>
                    <td>{{maint.frequency}}</td>
                    <td><DatePicker v-model="maint.start_date" :inputAttributes="{class: 'form-control'}" @input="updateMaint(maint)" /></td>
                    <td><DatePicker v-model="maint.end_date" :inputAttributes="{class: 'form-control'}" @input="updateMaint(maint)" /></td>
                    <td>
                        <div v-if="maint.cdi_file_id">
                            <a target="_blank" :href="`${baseUrl}/download/${maint.cdi_file_id}`">{{maint.cdi_file_id}}</a>
                            <span class="glyphicon glyphicon-trash cdi_link" @click="disableFileConfirm(maint.cdi_file_id)"></span>
                        </div>

                        <div v-else class="btn btn-default" @click="upload(maint.asset_maintenance_schedule_id)">
                            <span class="glyphicon glyphicon-plus"></span>
                        </div>
                    </td>
                    <td><input type="checkbox" @click.prevent="disableMaintConfirm(maint.asset_maintenance_schedule_id)"></td>
                </tr>
                <tr>
                    <td><input class="form-control" type="text" v-model="formFields.name.val"></td>
                    <td>
                        <select class="form-control" v-model="formFields.frequency.val">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="Biannually">Biannually</option>
                            <option value="Annually">Annually</option>
                        </select>
                    </td>
                    <td><DatePicker v-model="formFields.startDate.val" :inputAttributes="{class: 'form-control'}" /></td>
                    <td><DatePicker v-model="formFields.endDate.val" :inputAttributes="{class: 'form-control'}" /></td>
                    <td colspan="2"><input type="button" class="btn btn-default btn-block" value="Save" @click="newMaint"></td>
                </tr>
                </tbody>
            </table>
        </form>

        <ConfirmBox
            v-if="confirmBox"
            :heading="'Disable schedule?'"
            :body="'Are you sure you want to disable this maintenance schedule?'"
            @close="disableMaint"
        />
        <ConfirmBox
            v-if="fileConfirmBox"
            :heading="'Disable schedule file?'"
            :body="'Are you sure you want to disable this maintenance schedule file?'"
            @close="disableMaintFile"
        />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import ConfirmBox from "@/components/utils/ConfirmBox";
import DatePicker from '@/components/utils/DatePicker';
import helpers from "@/store/helpers";
export default {
    name: "Maintenance",
    components: {
        ConfirmBox,
        DatePicker,
    },
    props: ['maintenance'],
    data() {
        return {
            state: store.state,
            formFields: {
                name: { val: '', qsField: '[name]' },
                frequency: { val: 'Weekly', qsField: '[frequency]' },
                startDate: { val: '', qsField: '[start_date]' },
                endDate: { val: '', qsField: '[end_date]' },
            },
            confirmBox: false,
            disableID: 0,
            fileConfirmBox: false,
            fileDisableID: 0,
        }
    },
    methods: {
        newMaint: function() {
            store.apiWithObjectLoad('new_maintenance_schedule', helpers.getQSPairs(this.formFields));
            this.formFields.name.val = '';
            this.formFields.frequency.val = 'Weekly';
            this.formFields.startDate.val = '';
            this.formFields.endDate.val = '';
        },
        updateMaint: function(maint) {
            let options = {
                'params[asset_maintenance_schedule_id]': maint.asset_maintenance_schedule_id,
                'params[name]': maint.name,
                'params[start_date]': maint.start_date,
                'params[end_date]': maint.end_date,
            };
            store.apiWithObjectLoad('update_maintenance_schedule', options);
        },
        disableMaintConfirm: function(assetMaintenanceScheduleID) {
            this.disableID = assetMaintenanceScheduleID;
            this.confirmBox = true;
        },
        disableMaint: function(confirm) {
            if (confirm) {
                let options = {
                    'params[asset_maintenance_schedule_id]': this.disableID,
                    'params[disable]': 1,
                };
                store.apiWithObjectLoad('update_maintenance_schedule', options);
            }
            this.confirmBox = false;
            this.disableID = 0;
        },
        disableFileConfirm: function(id) {
            this.fileDisableID = id;
            this.fileConfirmBox = true;
        },
        disableMaintFile: function (confirm) {
            if (confirm) {
                let options = {
                    'params[cdi_file_id]': this.fileDisableID
                };
                store.apiWithObjectLoad('disable_maintenance_schedule_file', options);
            }
            this.fileConfirmBox = false;
            this.fileDisableID = 0;
        },
        upload: function (id) {
            this.$appStore.setFileUploadContext('asset_maintenance_schedule');
            this.$appStore.setFileUploadContextData({
                referenceID: store.state.object.id,
                reference2ID: id
            });
            this.$appStore.setFileUploadCallback(function () {
                store.load(store.state.object.id);
            });
        }
    },
    computed: {
        baseUrl: function () {
            return process.env.VUE_APP_URL;
        }
    }
}
</script>

<style scoped>

</style>
