import assetCache from '@/cache/asset.cache.js';
import cdiLocationCache from '@/cache/cdiLocation.cache';

export const assetStore = {
    state: {
        name: 'asset',
        title: 'Asset',
        titlePlural: 'Assets',
        nav: {
            base: 'asset',
            newView: true,
            views: [
                { view: 'details', label: 'Details', },
                { view: 'history', label: 'History', },
            ],
        },
        search: {
            sortFields: true,
            endPoint: 'assets',
            formFields: {
                label: {
                    val: '', 
                    qsField: '[fstrings][asset_label]', 
                    label: 'Label',
                    component: 'SearchTextInput'
                },
                assetModel: {
                    val: '', 
                    qsField: '[fstrings][asset_model]', 
                    label: 'Model',
                    component: 'SearchTextInput',
                },
                serial: {
                    val: '', 
                    qsField: '[fstrings][serial]',
                    label: 'Serial',
                    component: 'SearchTextInput',
                },
                macAddress: {
                    val: '',
                    qsField: '[fstrings][mac_address]',
                    label: 'MAC Address',
                    component: 'SearchTextInput',
                },
                type: {
                    val: 0,
                    qsField: '[numbers][asset_type]',
                    label: 'Type',
                    component: 'SearchSelectInput',
                    options: assetCache.ASSET_TYPE,
                },
                cdiLocationID: {
                    val: 0,
                    qsField: '[numbers][cdi_location_id]',
                    label: 'Location',
                    component: 'SearchSelectInput',
                    options: cdiLocationCache.CDI_LOCATIONS,
                },
                purchaseFrom: {
                    val: '',
                    qsField: '[dates][purchase_date_from]',
                    label: 'Purchase From',
                    component: 'SearchDateInput',
                },
                purchaseTo: {
                    val: '',
                    qsField: '[dates][purchase_date_to]',
                    label: 'Purchase To',
                    component: 'SearchDateInput',
                },
                assetDisable: {
                    val: 1,
                    qsField: '[isnot][asset_disable]',
                    label: 'Enabled Only',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 1,
                },
                ITAsset: {
                    val: 0,
                    qsField: '[isset][it_asset]',
                    label: 'IT Assets',
                    component: 'SearchCheckboxInput',
                    toggleHide: true,
                    defaultVal: 0,
                },
                pageSize: {val: 24, qsField: '[page_size]'},
                searchIndex: {val: 0, qsField: '[search_index]'},
                direction: {val: 'DESC', qsField: '[direction]'},
                orderBy: {
                    val: 'asset_label',
                    qsField: '[orderby]',
                    options: {
                        'asset_label': 'Label',
                        'type': 'Type',
                        'operator': 'Operator'
                    }
                },
            },
            resultFields: {
                asset_id: {header: 'ID', link: 'asset'},
                asset_label: {header: 'Label', link: ''},
                asset_model: {header: 'Model', link: ''},
                serial: {header: 'Serial', link: ''},
                type: {header: 'Type', link: ''},
                operator: {header: 'Operator', link: ''},
            }
        },
        object: {
            fields: [
                {
                    name: 'asset_id',
                    label: 'Asset ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'asset_label',
                    label: 'Label',
                    component: 'DetailsText',
                },
                {
                    name: 'asset_model',
                    label: 'Model',
                    component: 'DetailsText',
                },
                {
                    name: 'mfg_year',
                    label: 'Mfg Year',
                    component: 'DetailsText',
                },
                {
                    name: 'serial',
                    label: 'Serial #',
                    component: 'DetailsText',
                },
                {
                    name: 'asset_type',
                    label: 'Type',
                    component: 'DetailsSelect',
                    options: assetCache.ASSET_TYPE,
                },
                {
                    name: 'cdi_location_id',
                    label: 'Location',
                    component: 'DetailsSelect',
                    options: cdiLocationCache.CDI_LOCATIONS,
                },
                {
                    name: 'ip_address',
                    label: 'IP',
                    component: 'DetailsText',
                },
                {
                    name: 'mac_address',
                    label: 'MAC',
                    component: 'DetailsText',
                },
                {
                    name: 'OS',
                    label: 'OS',
                    component: 'DetailsText',
                },
                {
                    name: 'amps',
                    label: 'Amps',
                    component: 'DetailsText',
                },
                {
                    name: 'volts',
                    label: 'Volts',
                    component: 'DetailsText',
                },
                {
                    name: 'psi',
                    label: 'PSI',
                    component: 'DetailsText',
                },
                {
                    name: 'fluids',
                    label: 'Fluids',
                    component: 'DetailsText',
                },
                {
                    name: 'user_id',
                    label: 'Operator',
                    component: 'DetailsTypeahead',
                    displayField: 'operator',
                    cdiType: 'employee',
                },
                {
                    name: 'company_name',
                    label: 'Vendor',
                    component: 'DetailsText',
                },
                {
                    name: 'purchase_date',
                    label: 'Purchase Date',
                    component: 'DetailsDate',
                },
                {
                    name: 'purchase_price',
                    label: 'Purchase Price',
                    component: 'DetailsText',
                },
                {
                    name: 'warranty_exp_date',
                    label: 'Warranty Expiration',
                    component: 'DetailsDate',
                },
                {
                    name: 'policy_num',
                    label: 'Policy #',
                    component: 'DetailsText',
                },
                {
                    name: 'asset_desc',
                    label: 'Description',
                    component: 'DetailsTextarea',
                },
                {
                    name: 'asset_disable',
                    label: 'Disabled',
                    component: 'DetailsCheckbox',
                },
                {
                    name: 'maintenance_log',
                    label: 'Maintenance Log',
                    component: 'DetailsCheckbox',
                },
                {
                    name: 'it_asset',
                    label: 'IT Asset',
                    component: 'DetailsCheckbox',
                },
            ],
        },
    },
}