<template>
    <div>

        <div v-if="id">
            <h1>{{ state.object.data.asset_label }}</h1>
            <div v-show="isCurrentView('details')">
                <Details :data="state.object.data" />
                <Files :files="state.object.data.files" />
                <Images :images="state.object.data.photos" />
                <Damage :damage="state.object.data.damage" />
                <Claim :claims="state.object.data.claims" />
                <Maintenance :maintenance="state.object.data.maintenance_schedules" />
            </div>

            <History v-show="isCurrentView('history')" />
        </div>

        <New v-else-if="isCurrentView('new')" />

        <Search v-else />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import { assetStore } from './Asset.store.js';
import Search from '@/components/businessObjects/utils/Search';
import Details from '@/components/businessObjects/utils/Details';
import History from '@/components/businessObjects/utils/History';
import Damage from './Damage';
import Files from './Files';
import Images from './Images';
import Claim from './Claim';
import Maintenance from './Maintenance';
import New from './New';

export default {
    name: "Container",
    data() {
        return {
            state: store.state,
            assetState: assetStore.state,
        }
    },
    props: ['id'],
    components: {
        Search,
        Details,
        Files,
        History,
        Damage,
        Images,
        Claim,
        Maintenance,
        New,
    },
    created() {
        store.initialize(this.assetState);
        this.load();
    },
    methods: {
        load: function() {
            if (this.id){
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([this.state.object.data.asset_label, this.state.titlePlural]));
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
    },
    computed: {
        currentView: function() {
            return this.state.currentView;
        }
    },
    watch: {
        id: function (){
            this.load();
        },
        currentView: function() {
            if (this.state.currentView == 'history'){
                store.getHistory();
            }else if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}
</script>

<style scoped>

</style>