<template>
    <div class="spacing_top table-responsive">
        <table class="table">
            <tbody>
                <tr>
                    <th>File</th>
                    <th>Date</th>
                    <th>By</th>
                </tr>
                <tr v-for="file in files" v-bind:key="file.cdi_file_id">
                    <td>
                        <ProtectedLink :type="'download'" :linkType="'link'" :text="file.cdi_file_title" :id="file.cdi_file_id" :queryString="queryString" />
                    </td>
                    <td>
                        {{ file.cdi_file_datetime }}
                    </td>
                    <td>
                        {{ file.employee }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import helpers from "@/store/helpers";
    import ProtectedLink from "@/components/utils/ProtectedLink";

    export default {
        name: "Files",
        props: ['files'],
        components: {ProtectedLink},
        computed: {
            imgServer: function() {
                return process.env.VUE_APP_URL;
            },
            queryString: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}`;
            }
        }
    }
</script>

<style scoped>

</style>