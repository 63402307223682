<template>
    <div>
        <h1>New Asset</h1>
        <form @submit.prevent="newAsset">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Label</label>
                    <input type="text" v-model="formFields.assetLabel.val" class="form-control">
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Model</label>
                    <input type="text" v-model="formFields.assetModel.val" class="form-control">
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Serial #</label>
                    <input type="text" v-model="formFields.serial.val" class="form-control">
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Type</label>
                    <select class="form-control" v-model="formFields.assetType.val">
                        <option v-for="[cdiLookupID, assetType] in cache.assetCache.ASSET_TYPE" v-bind:key="cdiLookupID" :value="cdiLookupID">{{assetType}}</option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Location</label>
                    <select class="form-control" v-model="formFields.cdiLocationID.val">
                        <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Operator</label>
                    <Typeahead cdi_type="employee" v-bind:ID.sync="formFields.userID.val" />
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Vendor</label>
                    <Typeahead cdi_type="company" v-bind:ID.sync="formFields.companyID.val" />
                </div>
                <div class="col-sm-6 col-xs-12 form-group">
                    <label>Description</label>
                    <textarea v-model="formFields.assetDesc.val" class="form-control"></textarea>
                </div>
            </div>
            <input type="submit" class="btn btn-default" value="Save">
        </form>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js'
import cdiLocationCache from '@/cache/cdiLocation.cache.js';
import assetCache from '@/cache/asset.cache.js';
import helpers from "@/store/helpers";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
export default {
    name: "New",
    components: {
        Typeahead,
    },
    data() {
        return {
            state: store.state,
            cache: {
                cdiLocationCache,
                assetCache,
            },
            formFields: {
                assetLabel: { val: '', qsField: '[asset_label]' },
                assetModel: { val: '', qsField: '[asset_model]' },
                serial: { val: '', qsField: '[serial]' },
                assetType: { val: 88, qsField: '[asset_type]' },
                cdiLocationID: { val: 1, qsField: '[cdi_location_id]' },
                userID: { val: '', qsField: '[user_id]' },
                companyID: { val: '', qsField: '[company_id]' },
                assetDesc: { val: '', qsField: '[asset_desc]' },
            }
        }
    },
    created() {
        store.clearObjectData();
    },
    methods: {
        newAsset: function() {
            store.api('new_asset', helpers.getQSPairs(this.formFields))
                .then(result => {
                        this.$router.push({path: `/${this.state.nav.base}/${result.asset_id}`});
                    }
                );
        }
    },
}
</script>

<style scoped>

</style>