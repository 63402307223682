<template>
    <div class="spacing_top">
        <form @submit.prevent="saveDamage">
            <table class="table">
                <thead>
                <tr>
                    <th>Damage</th>
                    <th>Date</th>
                    <th>By</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(damageLine, index) in damage" v-bind:key="index">
                    <td>{{damageLine.description}}</td>
                    <td>{{damageLine.damage_date}}</td>
                    <td>{{damageLine.employee}}</td>
                </tr>
                <tr>
                    <td><input type="text" v-model="formFields.description.val" class="form-control"></td>
                    <td><DatePicker v-model="formFields.damageDate.val" :inputAttributes="{class: 'form-control'}" /></td>
                    <td><input class="btn btn-default btn-block" type="submit" value="Save"></td>
                </tr>
                </tbody>
            </table>
        </form>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import DatePicker from '@/components/utils/DatePicker';
import helpers from "@/store/helpers";
export default {
    name: "Damage",
    components: {
        DatePicker,
    },
    data() {
        return {
            state: store.state,
            formFields: {
                description: { val: '', qsField: '[description]' },
                damageDate: { val: '', qsField: '[damage_date]' },
            }
        }
    },
    props: ['damage'],
    methods: {
        saveDamage: function() {
            if (!this.formFields.description.val) {
                this.$appStore.errorBox('Must provide a description of the damage');
            }else if (!this.formFields.damageDate.val) {
                this.$appStore.errorBox('Must provide the date of the damage');
            }else{
                store.apiWithObjectLoad('save_damage', helpers.getQSPairs(this.formFields));
                this.formFields.description.val = '';
                this.formFields.damageDate.val = '';
            }
        }
    }
}
</script>

<style scoped>

</style>